/*
 *  STYLE 1
 */

#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

/*
 *  STYLE 2
 */

#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #D62929;
}

/*
 *  STYLE 3
 */

#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	/* background-color: #F5F5F5; */
	background-color: #abadaf;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	/*background-color: #F5F5F5;*/
	background-color: #abadaf;
}

#style-3::-webkit-scrollbar-thumb
{
	/* background-color: #000000; */
	background-color: #abadaf;
}

/*
 *  STYLE 4
 */

#style-4::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 2px solid #555555;
}


/*
 *  STYLE 5
 */

#style-5::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-5::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-5::-webkit-scrollbar-thumb
{
	background-color: #0ae;
	
	background-image: -webkit-gradient(linear, 0 0, 0 100%,
	                   color-stop(.5, rgba(255, 255, 255, .2)),
					   color-stop(.5, transparent), to(transparent));
}


/*
 *  STYLE 6
 */

#style-6::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-6::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-6::-webkit-scrollbar-thumb
{
	background-color: #F90;	
	background-image: -webkit-linear-gradient(45deg,
	                                          rgba(255, 255, 255, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(255, 255, 255, .2) 50%,
											  rgba(255, 255, 255, .2) 75%,
											  transparent 75%,
											  transparent)
}


/*
 *  STYLE 7
 */

#style-7::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style-7::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-7::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148)));
}

/*
 *  STYLE 8
 */

#style-8::-webkit-scrollbar-track
{
	border: 1px solid black;
	background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-8::-webkit-scrollbar-thumb
{
	background-color: #000000;	
}


/*
 *  STYLE 9
 */

#style-9::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-9::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-9::-webkit-scrollbar-thumb
{
	background-color: #F90;	
	background-image: -webkit-linear-gradient(90deg,
	                                          rgba(255, 255, 255, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(255, 255, 255, .2) 50%,
											  rgba(255, 255, 255, .2) 75%,
											  transparent 75%,
											  transparent)
}


/*
 *  STYLE 10
 */

#style-10::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style-10::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-10::-webkit-scrollbar-thumb
{
	background-color: #AAA;
	border-radius: 10px;
	background-image: -webkit-linear-gradient(90deg,
	                                          rgba(0, 0, 0, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(0, 0, 0, .2) 50%,
											  rgba(0, 0, 0, .2) 75%,
											  transparent 75%,
											  transparent)
}


/*
 *  STYLE 11
 */

#style-11::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style-11::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-11::-webkit-scrollbar-thumb
{
	background-color: #3366FF;
	border-radius: 10px;
	background-image: -webkit-linear-gradient(0deg,
	                                          rgba(255, 255, 255, 0.5) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(255, 255, 255, 0.5) 50%,
											  rgba(255, 255, 255, 0.5) 75%,
											  transparent 75%,
											  transparent)
}

/*
 *  STYLE 12
 */

#style-12::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
	border-radius: 10px;
	background-color: #444444;
}

#style-12::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#style-12::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #D62929;
	background-image: -webkit-linear-gradient(90deg,
											  transparent,
											  rgba(0, 0, 0, 0.4) 50%,
											  transparent,
											  transparent)
}

/*
 *  STYLE 13
 */

#style-13::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
	border-radius: 10px;
	background-color: #CCCCCC;
}

#style-13::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#style-13::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #D62929;
	background-image: -webkit-linear-gradient(90deg,
											  transparent,
											  rgba(0, 0, 0, 0.4) 50%,
											  transparent,
											  transparent)
}

/*
 *  STYLE 14
 */

#style-14::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.6);
	background-color: #CCCCCC;
}

#style-14::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-14::-webkit-scrollbar-thumb
{
	background-color: #FFF;
	background-image: -webkit-linear-gradient(90deg,
	                                          rgba(0, 0, 0, 1) 0%,
											  rgba(0, 0, 0, 1) 25%,
											  transparent 100%,
											  rgba(0, 0, 0, 1) 75%,
											  transparent)
}

/*
 *  STYLE 15
 */

#style-15::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style-15::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-15::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #FFF;
	background-image: -webkit-gradient(linear,
									   40% 0%,
									   75% 84%,
									   from(#4D9C41),
									   to(#19911D),
									   color-stop(.6,#54DE5D))
}

/*
 *  STYLE 16
 */

#style-16::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style-16::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-16::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #FFF;
	background-image: -webkit-linear-gradient(top,
											  #e4f5fc 0%,
											  #bfe8f9 50%,
											  #9fd8ef 51%,
											  #2ab0ed 100%);
}



/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
	.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		/*position: fixed;*/
		margin: auto;
			
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	.modal.left .modal-content,
	.modal.right .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
	.modal.left .modal-body,
	.modal.right .modal-body {
		padding: 15px 15px 80px;
	}

/*Left*/
	.modal.left.fade .modal-dialog{
		left: -320px;
		transition: opacity 0.3s linear, left 0.3s ease-out;
	}
	
	.modal.left.fade.in .modal-dialog{
		left: 0;
	}
        
/*Right*/
	.modal.right.fade .modal-dialog {
		right: -292px;
		transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	
	.modal.right.fade.in .modal-dialog {
		right: 0;
	}

/* ----- MODAL STYLE ----- */
	.modal-content {
		border-radius: 0;
		border: none;
	}

	.modal-header {
		border-bottom-color: #EEEEEE;
		background-color: #FAFAFA;
		padding:6px 15px;
	}
	.modal-title{
		font-size:16px;
	}


/*** ***/
.header1{
    padding: 0px 10px;
    /*border-bottom: 1px solid #107fb5;*/
    border-bottom: 1px solid #b5b5b5;
    background-color: rgb(3, 155, 229) !important;
	/*background-image:linear-gradient(to right, #f9aa1f , #c7418c);*/
	background-image:linear-gradient(90deg,#70d467 0,#1b6db1);
}
.header2{
    padding: 0px 10px;
    background-color: #eaeaea !important;
    border-bottom: 1px solid #d0d0d0;
	box-shadow: 1px 2px 1px #e4e4e4;
	margin-top:41px !important;
}
.header2 .dropdown-toggle::after {
    display: none;
}
ul.topLinks{
	list-style-type: none;
	margin:0;
	padding:0;
	display: inline;
}
ul.topLinks li{
	list-style:none;
	display: inline;
}
ul.topLinks li a{
    padding: 0px 12px;
    color: #e8e6e6 !important;
	cursor: pointer;
}
ul.topLinks li a>div{
	display:inline;
    font-size: 12pt;
    color: white;
	font-weight:500;
}

ul.topLinks2{
	list-style-type: none;
	margin:0;
	padding:0;
	display: inline;
}
ul.topLinks2 li{
	list-style:none;
	display: inline;
}
ul.topLinks2 li a{
    padding: 0px 12px;
    color: #6b6b6b !important;
	cursor: pointer;
}
ul.topLinks2 li a>div{
	display:inline;
    font-size: 12pt;
    color: #1d1b1b;
	font-weight:500;
}
.page-content{
	height: calc(75vh);
}

.payContainer .nav-pills .nav-link, .nav-pills .show>.nav-link {
    border-radius: 0px;
    border-right: 1px solid #615e5e;
	padding: 15px;
	color:#524d4d;
	
}
.payContainer .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
    border-radius: 0px;
    border-right: 1px solid #615e5e;
	background-image:linear-gradient(to right, #f9aa1f , #c7418c);
	
}
.payContainer .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    /*color: rgb(3, 155, 229);*/
	color:#fff;
    font-weight: 400;
	background-color:#d2316e;
}
.paymenu-icon{
    float: right;
    font-size: 20px;
    font-weight: 550;
}
.single-line-input{
	border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-radius: 0px;
}
.single-line-input:focus {
    box-shadow: none;
	border-bottom:2px solid #80bdff;
}
.pay-right-ad{
	float: right;
    width: 550px;
	height: 300px;
    padding: 10px;
    box-shadow: 1px 1px 4px 1px #b7b7b7;
    margin-bottom: 5px;
	cursor:pointer;
}
.dashboard-right-ad{
    width: 260px;
    height: 450px;
    padding: 10px;
    background-color: #1d98f7;	
	cursor:pointer;
}

.payContainer thead tr th{
	padding:5px;
	font-size:13px;
	background-color:#e9edef;
}

.payContainer tbody tr td{
	padding:5px;
	font-size:13px;
}
h4.my-title{
	font-size: 18px;
    font-weight: 400;
}
.label-text {
    color: #0a0a0a;
    font-family: sans-serif;
    font-size: 14px;
    margin-bottom: 0px;
}

.break_line {
    background: white;
    background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 250, from(rgba(0,0,0,0)), to(#fff));
    height: 1px;
    margin-top: 35px;
    margin-bottom: 25px;
    width: 100%;
    display: block;
    float: left;
    clear: both;
}
.break_line_text {
    top: -10px;
    position: relative;
    font-size: 12px;
}
.gradiant-order-box:hover{
	background-color: #b5b5b5;
    font-weight: 600;
	cursor:pointer;
}
.gradiant-order-box{
	/*background-image: linear-gradient(#e8dfdf,#9999a0);*/
    text-align: center;
    padding: 15px;
   /* border: 1px solid #c1bfbf;*/
    border-radius: 2px;
}

.srv-validation-message{
	font-size:12px;
	color:red;
}
.faq-title{
	color: #981253;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.bg-gradiant-primary{
	background-image: linear-gradient(to right, #71bf68 , #4a85c1);
	color: #fff;
}
.bg-gradiant-danger {
    background-image: linear-gradient(to right, #ff4444 , #4a85c1);
    color: #fff;
}

.bg-gradiant-success {
    background-image: linear-gradient(to right, #51e4c2 , #0c8a27);
    color: #fff;
}


/*** NEWS SCROLL ***/
.scroll-left {
 height: 50px;	
 overflow: hidden;
 position: relative;
}
.scroll-left p {
 position: absolute;
 width: 100%;
 height: 100%;
 margin: 0;
 line-height: 50px;
 text-align: center;
 /* Starting position */
 -webkit-transform:translateX(100%);	
 transform:translateX(100%);
 /* Apply animation to this element */
 -webkit-animation: scroll-left 10s linear infinite;
 animation: scroll-left 10s linear infinite;
}
/* Move it (define the animation) */
@-webkit-keyframes scroll-left {
 0%   { -webkit-transform: translateX(100%); }
 100% { -webkit-transform: translateX(-100%); }
}
@keyframes scroll-left {
 0%   { /* Browser bug fix */
 -webkit-transform: translateX(100%); /* Browser bug fix */
 transform: translateX(100%); 		
 }
 100% { /* Browser bug fix */
 -webkit-transform: translateX(-100%); /* Browser bug fix */
 transform: translateX(-100%); 
 }
}
/*** NEWS SCROLL ***/


.btn-gradiant-warning{
	    text-align: center;
    border: 0px;
    border-radius: 0px;
    background-image: linear-gradient(to right, #f8a21b , #fdd565);
    border: 1px solid #f39b1b;
    font-size: 14px;
    font-weight: 600;
}

.input-small{
	font-size: 14px !important;
    padding: 5px !important;
    margin: 0px !important;
    height: 25px !important;
}

/************** NEW CHECK BOX ****/
input[type=checkbox].css-checkbox {
	  position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height:1px; 
    width:1px; 
    margin:-1px; 
    padding:0;
    border:0;
}

input[type=checkbox].css-checkbox + label.css-label {
	padding-left:20px;
	height:15px; 
	display:inline-block;
	line-height:15px;
	background-repeat:no-repeat;
	background-position: 0 0;
	font-size:15px;
	vertical-align:middle;
	cursor:pointer;
}

input[type=checkbox].css-checkbox:checked + label.css-label {
	background-position: 0 -15px;
}

.css-label{
	background-image:url(http://csscheckbox.com/checkboxes/dark-check-green.png);
}

.lite-blue-check{background-image:url(http://csscheckbox.com/checkboxes/lite-blue-check.png);}


/*** IMAGE SELECT BOX CSS **/
.select-search-box {
    width: 100% !important;
    position: relative;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 0px !important;
    box-shadow: none;
    border-bottom: 1px solid #ced4da;
	padding:0px !important;
	margin:0px !important;
	color: #495057 !important;
}
input.select-search-box__search {
	width: 100%;
    line-height: 1;
    box-shadow: none;
	border-radius: 0px !important;
	padding:10px !important;
	color: #495057 !important;
}
ul.select-search-box__options li{
	padding: 4px 10px;
    font-weight: 100;
    font-size: 14px;
}
ul.select-search-box__options{
	padding: 0px;
}
.select-search-box::after {
	color: #868686 !important;
	font-size:12px !important;
}

/*** IMAGE SELECT BOX CSS **/

.inline-text-loader-show{
	position: absolute;
    z-index: 999;
    right: 30px;
    top: 15px;
}
.inline-text-loader-hide{
	display: none;
}

/********** NEW THEME CSS **************/
body {
  font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
    color: #007bff;
    font-weight: 550;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 48px; /* Space for fixed navbar */
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  /*padding: .75rem 1rem;*/
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.bg-dark {
   /* background: linear-gradient(90deg,#70d467 0,#1a74bf);*/
	background: linear-gradient(90deg,#62bdb9,#0a66b3);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}
ul.my-right-nav{
	display:inline;
}
ul.my-right-nav>li{
	display:inline;
}
ul.my-right-nav li>a{
	display:inline;
}
.my-dropdown-btn{
	padding:10px;
}
.my-dropdown-btn>button{
	font-size: 14px;
    padding: 0px;
    background: transparent;
    border: 0px;
}
.my-dropdown-btn>button:hover,.my-dropdown-btn>button:active,.my-dropdown-btn>button:focus,.my-dropdown-btn>button:visited{
	border: 0px;
	background: transparent;
	box-shadow:none !important;
}

.my-dropdown-btn>.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
	border: 0px;
	background: transparent;
	box-shadow:none !important;
}
.my-dropdown-btn .dropdown-toggle::after{
	display:none;
}
.my-dropdown-btn a{
	font-size:14px;
}
/********** NEW THEME CSS **************/

.side-menu-dropdown-open >.nav-link{
	font-weight:bold;
}
.side-menu-dropdown{
	/* background-color: white;
    border: 1px solid grey;
    width: 93%;
    margin-left: 6px;
	margin-bottom:5px; */
}
.side-menu-dropdown-open ul{
	display: block;
    border-left: 1px solid grey;
    margin-left: 30px;
    padding-left: 12px;
    list-style: none;
}
.side-menu-dropdown-open li a{
    color: #545353;
    text-decoration: none;
}
.side-menu-dropdown-open li{
	  padding: 4px 0px;
	  cursor:pointer;
	  font-size:13px;
}
.side-menu-dropdown-open li.active > a{
	font-weight:600;
	color:#3c3c3c;
}
#ps-sidebar{
  transition: width 2s;
  -webkit-transition: width 2s; /* Safari 3.1 to 6.0 */
}



/********** NEW THEME CSS **************/
/* On screens that are 600px or less */
@media (min-width: 600px) {
	.ps-navbar-menu{
		display:none !important;
	}
}
@media (max-width: 600px) {
  #ps-footer, .my-right-nav, .ps-navbar-icon, .login-banner, #login-footer{
	  display:none !important;
  }
  #ps-sidebar{
	  width:250px; /* Safari */
	transition: width 2s;
  }
  .payContainer{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
 
}

#showLoader{
	background-color: rgba(16, 16, 16, 0.62);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999999;
    width: 100%;
    height: 100%;
	text-align: center;
}
#showLoaderInner{
	margin-top:20% !important;
}

.pagination{display:inline-block;padding-left:0;margin:20px 0;border-radius:4px}.pagination>li{display:inline}
.pagination>li>a,.pagination>li>span
{position:relative;float:left;padding:6px 12px;margin-left:-1px;line-height:1.42857143;color:#337ab7;text-decoration:none;background-color:#fff;border:1px solid #ddd}
.pagination>li:first-child>a,.pagination>li:first-child>span
{margin-left:0;border-top-left-radius:4px;border-bottom-left-radius:4px}
.pagination>li:last-child>a,.pagination>li:last-child>span
{border-top-right-radius:4px;border-bottom-right-radius:4px}
.pagination>li>a:focus,.pagination>li>a:hover,.pagination>li>span:focus,.pagination>li>span:hover
{z-index:2;color:#23527c;background-color:#eee;border-color:#ddd}
.pagination>.active>a,.pagination>.active>a:focus,.pagination>.active>a:hover,.pagination>.active>span,.pagination>.active>span:focus,.pagination>.active>span:hover
{z-index:3;color:#fff;cursor:default;background-color:#337ab7;border-color:#337ab7}


.md-form {
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.md-form .form-control {
    margin: 0 0 .5rem 0 !important;
    border-radius: 0 !important;
    padding: .3rem 0 .55rem 1.3rem !important;
    background-image: none !important;
    background-color: transparent !important;
    height: 150px !important;
	/*border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;*/
    border-radius: 0px !important;
}

.md-form textarea.md-textarea {
    overflow-y: auto;
    padding: 1.5rem 0;
    resize: none;
}

.md-form label {
    position: absolute;
    top: .65rem;
    left: 0;
    transition: .2s ease-out;
    cursor: text;
    color: #757575;
	font-size: 1rem;
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.label-success {
    background-color: #5cb85c;
}

.label-danger {
    background-color: #d9534f;
}

.form_view {
	
	 background-color: #ffffff !important;
	 border-bottom: 0px !important;
	 font-weight: bold !important;
	
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
     font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #4caf50;
    border-radius: 10px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow{

	min-height: 200px;
    max-height: 500px;
    overflow-y: auto;
	
}


/***** RESPONSIV SIDEBAR FOR MOBILE VIEW *****/
